<template>
  <div>
    <div class="content-body">
      <div class="container-fluid">
        <div class="row fill-height justify-content-center">
          <div class="col-12 col-md-6 col-lg-5">
            <div class="card print">
              <div class="card-header">
                <h4 class="card-title">GetCoins Bank Information</h4>
                <button class="btn btn-primary" @click="print">Print</button>
              </div>
              <div class="card-body">
                <div class="important-info">
                  <ul>
                    <li>
                      <i class="mdi mdi-checkbox-blank-circle"></i>
                      <b>Depository Bank Name:</b> Drummond Community Bank
                    </li>
                    <li>
                      <i class="mdi mdi-checkbox-blank-circle"></i>
                      <b>Bank Address:</b> 1627 North Young Boulevard, Chiefland, FL 32626
                    </li>
                    <li>
                      <i class="mdi mdi-checkbox-blank-circle"></i>
                      <b>Credit To:</b> Evergreen ATM LLC
                    </li>
                    <li>
                      <i class="mdi mdi-checkbox-blank-circle"></i>
                      <b>Address:</b> 401 Ryland St, STE 200-A, Reno, NV 89502
                    </li>

                    <li>
                      <i class="mdi mdi-checkbox-blank-circle"></i>
                      <b>Routing Number:</b> {{bankDetails.routing_number}}
                    </li>
                    <li>
                      <i class="mdi mdi-checkbox-blank-circle"></i>
                      <b>Account Number:</b> {{bankDetails.account_number}}
                    </li><br>

                    <h4 class="card-title">Instructions:</h4> <br>
                    
                    <li><b>Credit To:</b> Evergreen ATM LLC </li>
                    <li><b>Address: </b> 401 Ryland St, STE 200-A, Reno, NV 89502</li>
                    <li><b>Memo/Reference Message:</b> Your phone number that you signed up with. </li>
                    <li>Note the reference message must be included or your deposit processing may be delayed</li>
                                    </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import api from '@/api';
import client from '@/api/client';

export default {
  data: () => ({
    bankDetails: [],
    }),

 mounted() {
    this.getBankDetails();
  },

  methods: {
    getBankDetails() {
        api.deposit.details().then((res) => {
        this.bankDetails = res;
        //console.log(res);
    });
  },
    print() {
        window.print();
        const newWindow = window.open('', 'Print Details', 'height=400, width=600');
        if (document) {
          newWindow.document.write(`<html><head><title> ${document.title} </title>`);
        }
        newWindow.document.write('</head><body>');
        newWindow.document.write('<h1> GetCoins Account </h1>');
        newWindow.document.write(document.querySelector('.print').innerHTML);
        newWindow.document.write('</body></html>');

        newWindow.document.close(); // necessary for IE >= 10
        newWindow.focus(); // necessary for IE >= 10*/

        newWindow.print();
        newWindow.close();

        return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
    .print {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        button {
            display: none;
        }
    }
}
</style>